import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import LinkError from "./pages/LinkError";
import RetrievePwd from "./pages/RetrievePwd";
import MoLinkError from "./pages/moLinkError";
import MoRetrievePwd from "./pages/moRetrievePwd";

export const routes: RouteObject[] = [
  {
    path: "/emailLinkError/pc",
    element: <LinkError />,
  },
  {
    path: "/emailReset/pc",
    element: <RetrievePwd />,
  },
  {
    path: "/emailReset/mobile",
    element: <MoRetrievePwd />,
  },
  {
    path: "/emailLinkError/mobile",
    element: <MoLinkError />,
  },
  {
    path: "",
    element: <Navigate to="/emailReset/pc" />,
  },
];

export default routes;
