import { FC, useState } from "react";
import { Form, Toast, Button } from "@douyinfe/semi-ui";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { validatePasswprd } from "../../services";
import { userForgetPwdFetch } from "../../services";
import SuccessModal from "./components/SuccessModal";
import "./index.less";
const RetrievePwd: FC = () => {
  const [isSuccess, setIssuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const search = window.location.search?.split("?")[1] ?? "";
  const paramArr = queryString.parse(search);
  const token = paramArr.token as string;
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const handleSubmit = async (val) => {
    const { password } = val;
    setBtnLoading(true);
    const res: any = await userForgetPwdFetch({
      password,
      token: token,
    });
    if (res.code === 200) {
      setIssuccess(true);
    } else if (res.code === 502) {
      Toast.error({
        content: res?.msg ?? "error",
        duration: 3,
        theme: "light",
      });
      navigate(`/emailLinkError/pc?email=${paramArr.email}`);
    } else {
      Toast.error({
        content: res?.msg ?? "error",
        duration: 3,
        theme: "light",
      });
    }
    setBtnLoading(false);
  };
  return (
    <div className="pc_retrievePwd">
      <div className="pc_title">Reset Twitter Suitcase Password</div>
      <div className="pc_des">
        <div>Type the email used when you signed up for</div>
        <div>Twitter Suitcase and your new password.</div>
      </div>
      <Form onSubmit={(values) => handleSubmit(values)}>
        {({ formState, values }) => (
          <>
            <Form.Input
              showClear
              field="password"
              mode="password"
              placeholder="Use 6 ~18 characters with a mix of letters, numbers"
              trigger="change"
              validate={validatePasswprd}
              noLabel={true}
            />
            <Button
              htmlType="submit"
              loading={btnLoading}
              disabled={
                !(JSON.stringify(formState.errors) === "{}" && values.password)
              }
            >
              RESET PASSWORD
            </Button>
          </>
        )}
      </Form>
      <SuccessModal
        visible={isSuccess}
        handleCancel={() => setIssuccess(false)}
      />
    </div>
  );
};
export default RetrievePwd;
