import { FC, useEffect, useState } from "react";
import { Button, Toast } from "@douyinfe/semi-ui";
import { getVerifyCodeFetch } from "../../services";
import "./index.less";

const LinkError: FC = () => {
  const search: string = window.location.search?.split("?")[1] ?? "";
  const emailstring: string = search?.split("email=")[1];
  const email: string = emailstring?.split("&token=")[0];
  const [saveEmail, setSaveEmail] = useState<string>("");
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const btlClick = async () => {
    setBtnLoading(true);
    const res: any = await getVerifyCodeFetch({
      email,
      type: 1,
    });
    if (res.code === 200) {
      setBtnLoading(false);
      Toast.success({
        content: res?.msg ?? "error",
        duration: 3,
        theme: "light",
      });
    } else if (res.code === 500) {
      Toast.warning({
        content: res?.msg ?? "error",
        duration: 3,
        theme: "light",
      });
      setBtnLoading(false);
    } else {
      Toast.error({
        content: res?.msg ?? "error",
        duration: 3,
        theme: "light",
      });
      setTimeout(() => {
        setBtnLoading(false);
      }, 6000);
    }
  };
  useEffect(() => {
    setSaveEmail(email);
  }, []);
  return (
    <div className="mo_linkError">
      <div className="title">The link has expired</div>
      <div className="des">
        <div>Password reset links are only vaild for 24 hours.</div>
        <div>Would you like us to send you a new reset password link?</div>
      </div>
      <Button onClick={btlClick} loading={btnLoading}>
        SENT
      </Button>
      <div className="message">Please check your email, {saveEmail}</div>
    </div>
  );
};
export default LinkError;
