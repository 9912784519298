import { FC } from "react";
import { Button, Modal } from "@douyinfe/semi-ui";
import logo from "../../../../assets/logo.png";
import closePng from "../../../../assets/close.png";
import successPng from "../../../../assets/success.png";
import "./index.less";

const SuccessModal: FC<{
  visible: boolean;
  handleCancel: () => void;
}> = ({ visible, handleCancel }) => {
  const titleStyle = {
    fontSize: 20,
    color: "#1d9cf0",
    fontWeight: 500,
  };
  const successStyle = {
    marginTop: 20,
    fontSize: 20,
    color: "#52c419",
    fontWeight: 500,
  };
  const desStyle = {
    marginTop: 34,
    marginBottom: 20,
    fontSize: 16,
    color: "#859199",
  };
  const btnStyle = {
    width: 338,
    height: 48,
    color: "#fff",
    background: "#1d9cf0",
  };
  const gotoHomePage = () => {
    handleCancel();
  };
  return (
    <>
      <Modal
        closable={false}
        width={398}
        height={462}
        visible={visible}
        maskClosable={false}
        footer={null}
      >
        <div
          style={{
            textAlign: "right",
            cursor: "pointer",
          }}
          onClick={handleCancel}
        >
          <img src={closePng} alt="" />
        </div>
        <div
          style={{
            textAlign: "center",
            fontFamily: "Poppins, Poppins-Medium",
            marginTop: 20,
          }}
        >
          <img src={logo} />
          <p style={titleStyle}>Reset Twitter Suitcase Password</p>
          <img src={successPng} alt="" />
          <p style={successStyle}>Success!</p>
          <div style={desStyle}>
            <div>Your password has been reset.</div>
            <div>Go back to the extension to sign in.</div>
          </div>
          <Button type="primary" style={btnStyle} onClick={gotoHomePage}>
            LOG IN
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default SuccessModal;
