import React, { useEffect } from "react";
import classNames from "classnames";

import routesConfig from "./routes";
import logo from "./assets/logo.png";
import "./App.less";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";

const EmailReset: React.FC<React.PropsWithChildren> = ({ children }) => {
  const routes = useRoutes(routesConfig);
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile] = React.useState(() =>
    /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  );

  useEffect(() => {
    if (location.pathname === "/emailLinkError/pc" && isMobile) {
      navigate("/emailLinkError/mobile?" + location.search);
    }
    if (location.pathname === "/emailLinkError/mobile" && !isMobile) {
      navigate("/emailLinkError/pc" + location.search);
    }
    if (location.pathname === "/emailReset/pc" && isMobile) {
      navigate("/emailReset/mobile" + location.search);
    }
    if (location.pathname === "/emailReset/mobile" && !isMobile) {
      navigate("/emailReset/pc" + location.search);
    }
  }, []);
  return (
    <div
      className={classNames({
        email_reset__container: isMobile,
        pc_email_reset__container: !isMobile,
      })}
    >
      <div className="contain">
        <img src={logo} alt="" />
        {routes}
      </div>
    </div>
  );
};
export default EmailReset;
