import axios, { AxiosPromise, AxiosResponse } from "axios";
import {
  getVerifyCodeParam,
  SuccessDataType,
  UserForgetType,
} from "../../../src/types/user";
const baseurl = "https://twittersuitcase.com/user";
// const baseurl = "http://10.10.1.10:8085";

export const getVerifyCodeFetch = (
  params: getVerifyCodeParam
): AxiosPromise<AxiosResponse<SuccessDataType>> => {
  return new Promise(async (resolve) => {
    const res = await axios({
      method: "get",
      url: `${baseurl}/user/SendVerificationCode`,
      params,
    });
    resolve(res.data);
  });
};

export const userForgetPwdFetch = (
  //通过邮箱忘记密码-重置
  params: UserForgetType
): AxiosPromise<SuccessDataType> => {
  return new Promise(async (resolve) => {
    const res = await axios({
      method: "put",
      url: `${baseurl}/user/forgetPassword`,
      data: params,
    });
    resolve(res.data);
  });
};

export const validatePasswprd = (val: string) => {
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const check = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W].{5,17}$/;
  return sleep(100).then(() => {
    if (!check.test(val)) {
      return "Use 6 ~18 characters with a mix of letters, numbers";
    } else {
      return "";
    }
  });
};
